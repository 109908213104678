body {
  margin: 0;
  font-family: "Anton", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
@font-face {
  font-family: "Anton";
  src: local("Anton"), url("./fonts/Anton-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("./fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Robo Noto San JP";
  src: local("Robo Noto San JP"), url("./fonts/NotoSansJP-Regular.otf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"), url("./fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Oswald";
  src: local("Oswald"), url("./fonts/Oswald-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Permanent Marker";
  src: local("PermanentMarker"), url("./fonts/PermanentMarker-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Rubik";
  src: local("Rubik"), url("./fonts/Rubik-Regular.ttf") format("truetype");
  font-weight: normal;
}
