.dialog {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
}
.dialogHeader {
  height: 2.5rem;
}
.tag {
  padding: 10px;
  background-color: #d6d3d3;
  border-radius: 5%;
  cursor: pointer;
  float: left;
}
.tagClose {
  padding: 10px;
  float: right;
  background-color: #d6d3d3;
  border-radius: 5%;
}
.dialogBody {
  height: 20vh;
  background-color: #d6d3d3;
}
.dialogBodyContent {
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow-y: hidden;
  overflow-x: auto;
  background-color: #d6d3d3;
  justify-content: center;
  width: 100%;
}
.textures {
  display: flex;
  margin: auto;
  width: fit-content;
}
