.outLineCircle {
  width: 140px;
  transition: 0.1s ease-in;
  min-width: 50px;
  background-size: cover;
  background-position: center;
  border: 3px dotted transparent;
}
.outLineCircle:hover {
  border: 3px dashed black;
}
.outLineCircleActive {
  width: 140px;
  transition: 0.1s ease-in;
  min-width: 50px;
  background-size: cover;
  background-position: center;
  border: 3px dotted black;
}
