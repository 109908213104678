.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  overflow: hidden;
  background-color: #dee2e5 !important;
}

.partsButtonActive {
  background: #ffff00 !important;
  border-color: #ffff00 !important;
  border-width: 2px !important;
}

.react-tabs__tab {
  padding: 0 !important;
  margin-left: 5px;
}
.react-tabs__tab .svg-inline--fa {
  vertical-align: bottom !important;
}

.react-tabs__tab > span,
.react-tabs__tab > svg {
  margin: 6px 12px 0 12px !important;
  pointer-events: none;
}

.reactEasyCrop_Container {
  background: #fff;
}

.reactEasyCrop_CropArea {
  border: none !important;
}
.reactEasyCrop_CropAreaGrid::after,
.reactEasyCrop_CropAreaGrid::before {
  border: 0.5px dashed rgba(0, 0, 0, 0.2) !important;
}
.btn-outline-secondary:focus,
.btn:focus {
  z-index: 0 !important;
}
