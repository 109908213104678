.btn {
  background-color: #000000;
  pointer-events: none;
}
.btnWrapper {
  height: 150px;
  text-align: center;
  display: block;
  background-color: transparent;
  margin-bottom: 1px;
  margin-top: 1rem;
  float: left;
  width: fit-content;
}
.outLineCircle {
  width: 102px;
  height: 100px;
  border-width: 3px;
  border-style: none;
  transition: 0.1s ease-in;
  min-width: 50px;
  background-size: cover;
  background-position: center;
}
.outLineCircle:hover {
  width: 102px;
  height: 102px;
  border-style: dashed;
  border-radius: 50%;
  padding-top: 7px;
}
.circle {
  text-align: center;
  display: block;
  height: 80px;
  width: 80px;
  margin-bottom: 0px;
  border-radius: 50%;
  margin-left: 8px;
  /* margin-right: 7px; */
  margin-top: 1px;
  border: solid 1px;
  pointer-events: none;
}
.circleText {
  margin-bottom: 5px;
  margin-top: 10px;
}
.outLineCircleActive {
  width: 102px;
  height: 102px;
  border-width: 3px;
  border-style: none;
  transition: 0.1s ease-in;
  min-width: 50px;
  background-size: cover;
  background-position: center;
  border: 3px dotted black;
  border-radius: 50%;
  padding-top: 7px;
}
