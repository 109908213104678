.header {
  position: absolute;
  bottom: 0.2rem;
  width: 100%;
}
.headerImage {
  float: left;
  margin-top: 10px;
  width: 6rem;
  z-index: 1031;
  position: fixed;
  top: 0px;
  left: 0px;
}
.menuDrawerDiv {
  position: fixed;
  right: 10px;
}
